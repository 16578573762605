<script>
  /**
   * Renders a virtual node. See https://vuejs.org/v2/guide/render-function.html#The-Virtual-DOM.
   */
  export default {
    props: {
      /** The `VNode` object to render. */
      node: {
        type: Object,
        required: true,
      },
    },
    render() {
      return this.node
    },
  }
</script>
